import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import { _ } from "vue-underscore";

export default {
  data: () => ({
    emailFollowupTemplateList: [],
    selected: "",
    searchText: "",
    selectedUser: "",
    selectedId: 0,
    selectedRows: [],
    usersList: [],
    selectedItem: null,
    showLoader: false,
    paginate: {
      page_count: 1,
      selected: 1,
      per_page: 50,
      current_page: 1,
      last_page: 0,
    },
    recordTakeOptions: [25, 50, 100, 200],
  }),

  mixins: [paginationMixin],
  components: { "header-list": headerList, PaginationComponent },

  computed: {
    selectAllList() {
      if (this.emailFollowupTemplateList.length > 0) {
        return (
          _.where(this.emailFollowupTemplateList, { isSelected: true })
            .length === this.emailFollowupTemplateList.length
        );
      }
      return false;
    },
    hasemailFollowupTempleShowPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leadEmailFollowupTemplate-index";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasemailFollowupTempletAddPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leadEmailFollowupTemplate-store";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasemailFollowupTempletDeletePermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leadEmailFollowupTemplate-destroy";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasemailFollowupTempletUpdatePermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leadEmailFollowupTemplate-update";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasemailFollowupTempletCopyPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leadEmailFollowupTemplate-copy";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    hasemailFollowupTempletHideShowPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leadEmailFollowupTemplate-updatestatus";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
  },
  methods: {
    getData() {
      let _vm = this;
      _vm.getAll();
    },
    getUserList() {
      let _vm = this;
      this.axios
        .get("/user-list")
        .then(function (response) {
          _vm.usersList = response.data.data;
        })
        .catch(function () {});
    },
    checkUncheckedList(event) {
      this.emailFollowupTemplateList.forEach((element) => {
        element.isSelected = event.target.checked;
      });
    },
    openModal(emailFollowupTemplateList) {
      this.selectedItem = emailFollowupTemplateList;
    },
    closeModal() {
      this.selectedItem = null;
    },
    deleteRecord(id) {
      let _vm = this;
      let ids = [];
      if (this.selectedId > 0) {
        ids = [this.selectedId];
      } else {
        this.emailFollowupTemplateList.forEach((element) => {
          if (element.isSelected == true) {
            ids.push(element.id);
          }
        });
      }
      _vm.axios
        .post("/email-followup-templates-deletes", { ids: ids })
        .then(function () {
          _vm.getAll();
          _vm.selectedId = 0;
        })
        .catch(function () {});
    },
    search(value) {
      this.getAll();
    },
    getQueryString() {
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page;

      if (this.selectedUser) {
        queryString += "&agent=" + this.selectedUser.id;
      }
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      this.axios
        .get("/email-followup-templates" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          _vm.emailFollowupTemplateList = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function () {});
    },
    copyemailFollowupTemplate(emailFollowupTemplate, event) {
      let _vm = this;
      let _msg =
        "Are you sure you want to Duplicate this Email Followup template ?";

      this.$dialog
        .confirm(_msg)
        .then(function () {
          _vm.axios
            .post("/email-followup-templates/copy/" + emailFollowupTemplate.id)
            .then(function (response) {
              _vm.getAll();
              _vm.selectedId = 0;
            });
        })
        .catch(function () {
          _vm.getAll();
        });
    },
    updateemailFollowupTemplateStatus(emailFollowupTemplate, event) {
      let _vm = this;
      let _msg =
        emailFollowupTemplate.is_display == true
          ? "Are you sure you want to Deactivate this Email Followup Template?"
          : "Are you sure you want to Active this Email Followup Template?";
      let _isDisplay = emailFollowupTemplate.is_display == true ? 1 : 0;
      this.$dialog
        .confirm(_msg)
        .then(function () {
          _vm.axios
            .post(
              "/email-followup-templates-update-status/id?id=" +
                emailFollowupTemplate.id +
                "&is_display=" +
                _isDisplay
            )
            .then(function () {
              _vm.getAll();
            })
            .catch(function () {
              document.getElementById(
                "regions-checkbox-" + emailFollowupTemplate.id
              ).checked = emailFollowupTemplate.is_display;
            });
        })
        .catch(function () {
          document.getElementById(
            "regions-checkbox-" + emailFollowupTemplate.id
          ).checked = emailFollowupTemplate.is_display;
        });
    },
    handleCheck(e) {
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          this.selectedRows = this.emailFollowupTemplateList.map(
            (item) => "checkbox" + item.id
          );
          this.emailFollowupTemplateList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          this.selectedRows = [];
          this.emailFollowupTemplateList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          this.selectedRows.push(e.target.id);
        } else {
          this.selectedRows = this.selectedRows.filter((item) => {
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
    },
  },
  mounted() {
    this.getAll();
    this.getUserList();
  },
};
